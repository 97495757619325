@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700&display=swap');
@import "variables.modules";


body {
    font-family: 'Roboto', Helvetica, sans-serif;
    min-height: 1vh;
    width: 100%;
    height: 100%;
    color: rgb(248, 250, 252);
    background-color: rgb(15, 23, 42);
    box-sizing: border-box;
}



li {
    color: $dark;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

*, html {
    scroll-behavior: smooth !important;
}

a,
a:link,
a:hover,
a:visited,
a:active {
    text-decoration: none;
}

img {
    max-width: 100%;
}

ul {
    list-style-type: none;
}