@import "../../variables.modules";

i {
  padding-left: 0.5rem;

  &.red {
    color: $red;
  }

  &.green {
    color: $green;
  }
  &.amber{
    color: $yellow;
  }
}

.awards{
  color: $yellow;
  white-space: pre-line;
}


.terminal {
  font-family: Courier New, Courier, monospace;
  height: 100%;
  p, li {
    margin: 1rem 0;
  }

  a, a:visited {
    animation: changeColors 3s infinite;
    font-weight: bold;
  }
}
.green {
  color: $green;
}

@keyframes changeColors {
  0% {
    color: #00a47f;
  }
  33.3% {
    color: #1d91e3;
  }
  66.6% {
    color: #d419fe;
  }
  100% {
    color: #00a47f;
  }
}

.hidden {
  background: transparent !important;
  color: transparent !important;
  pointer-events: none !important;
  user-select: none !important;
  z-index: -1 !important;
  transform: none !important;
}
.hidden * {
  background: transparent !important;
  color: transparent !important;
  pointer-events: none !important;
  user-select: none !important;
}