.container {
    scroll-behavior: smooth;
    position: relative;
    overflow-y: scroll;
    overscroll-behavior-y: contain;
    scroll-snap-type: y mandatory;
    height: 100vh;
    //width: 100%;
    //-ms-overflow-style: none;
    //scrollbar-width: none;
    //&::-webkit-scrollbar {
    //    display: none;
    //}
}